import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Main } from "./pages/Main";
import "./index.sass";
import { Provider } from "react-redux";
import { setupStore } from "./app/api/store/store";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
  },
]);

const store = setupStore();

const rootElement = document.getElementById("root") as HTMLElement;
ReactDOM.createRoot(rootElement).render(
  <Provider store={store}>
    <div data-theme="tkThemeLight">
      <RouterProvider router={router} />
    </div>
  </Provider>
);