import { Icon, Typography, Button, Chip, Input } from '@technokratos/prometheus-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from "./index.module.sass";
import { Message, MessageDirection } from '../../app/api/models/message';
import { MessageItem } from '../MessageItem';
import { messageAPI } from '../../app/api/services/MessageService';

const CHIPS = [
	'Мои отпускные дни', 'Как написать заявление на отпуск', 'Актуальные мероприятия', 'Как забронировать переговорку', 'Где найти свою карту знаний'
];


export const Chat = () => {
	const [value, setValue] = useState<string>('');
	const [messages, setMessages] = useState<Message[]>([]);

	const [postMessage, {isLoading: isLoadingNewMessage}] = messageAPI.useCreateMessageMutation();
	const [currentController, setCurrentController] = useState<AbortController | null>(null);
	const [sendMessage, setSendMessage] = useState<boolean>(false);
	const messagesEndRef = useRef<HTMLLIElement>(null);
	const chatRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const storedMessages = sessionStorage.getItem('messages');
		if (storedMessages) {
		  setMessages(JSON.parse(storedMessages));
		}

		if (chatRef.current) {
			chatRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
		}

	  }, []);
	
	  useEffect(() => {
		sessionStorage.setItem('messages', JSON.stringify(messages));

		if (messagesEndRef.current) {
			messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	  }, [messages]);


	  const handleSendMessage = useCallback(async () => {
		if (value.trim()) {
			const newMessage: Message = {
				msg_id: Date.now().toString(),
				message: value.trim(),
				direction: MessageDirection.IN
			};
			setMessages([...messages, newMessage]);

			const controller = new AbortController();
			setCurrentController(controller);

			try {
				const response = await postMessage({ value: value.trim(), signal: controller!.signal });
				if (response.data) {
					setMessages([...messages, newMessage, response.data]);
				}
			  } catch (error) {
				console.error('Error:', error);
			  }
			  finally {
				setValue('');
				setCurrentController(null);
			}
		}
	}, [value, messages, postMessage]);

	  useEffect(() => {
		if (sendMessage && value.trim()) {
			handleSendMessage();
			setSendMessage(false);
		}
	}, [handleSendMessage, sendMessage, value]);
	

	const handleChangeValue = (value: string) => {
		setValue(value);
	  };
	
	  const handleChipClick = (chip: string) => {
		setValue(chip);
		setSendMessage(true);
	};

	
	  const handleKeyDown = (event: any) => {
		if (event.key === 'Enter') {
		  event.preventDefault();
		  handleSendMessage();
		}
	  };

	const handleCancelRequest = () => {
		if (currentController) {
			currentController.abort();
		}
	};

	const handleDeleteStory = () => {
		setMessages([]);
	};

	  return (
		<section className={styles.wrapper} ref={chatRef}>
			<Button
				onClick={handleDeleteStory}
				className={`${styles.button} ${styles.button_delete}`}
				theme="inline"
				disabled={!messages.length || isLoadingNewMessage}
				>
				<Icon icon='delete_outline' size={24} />
			</Button>
			<div className={styles.container}>
				{!messages.length ? (
					<section className={styles.questions}>
					<Icon icon='/icons/assistent.svg'></Icon>
					<Typography variant="header-H3">
						Частые вопросы
					</Typography>
					<ul className={styles.chips}>
						{CHIPS.map((chip, index) => (
						<li key={index}>
							<Button onClick={() => handleChipClick(chip)} theme='inline'>
							<Chip className={styles.chip}>
								{chip}
							</Chip>
							</Button>
						</li>
						))}
					</ul>
					</section>
				) : (
					<ul className={styles.messages}>
					{messages.map((message, index) => (
						<li key={message.msg_id} ref={index === messages.length - 1 ? messagesEndRef : null}>
						<MessageItem message={message} />
						</li>
					))}
					{isLoadingNewMessage && (
						<li>
							<MessageItem showLoader={true} />
						</li>
					)}
					</ul>
				)}
				<section className={styles.controls}>
					<Input
					placeholder='Введите запрос'
					disabled={isLoadingNewMessage}
					value={value}
					onChange={handleChangeValue}
					onKeyDown={handleKeyDown}
					className={styles.input}
					type='text'
					/>
					{!isLoadingNewMessage ? (
						<Button
						onClick={handleSendMessage}
						className={styles.button}
						theme="secondary"
					>
					<Icon icon='arrow_up_outline' size={24} />
					</Button>
					) : (
						<Button
							onClick={handleCancelRequest}
							className={styles.button}
							theme="secondary"
							>
							<Icon icon='/icons/stop.svg' size={24} />
						</Button>
					)}
				</section>
			</div>
		</section>
	  );
	  
};

