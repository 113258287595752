import { Icon, Typography, Select } from '@technokratos/prometheus-react';
import React, { useState } from 'react';
import styles from "./index.module.sass";

const SIDEBAR = [
	{
		title: "ассистенты организации",
		items: [
			{
				name: 'Информис',
				description: 'База знаний',
				icon: 'informis',
			},
			{
				name: 'Эвентус',
				description: 'Календарь',
				icon: 'eventus',
			},
			{
				name: 'Конферос',
				description: 'Переговорные',
				icon: 'conferus',
			},
		],
	},
	{
		title: "HUMAN RESOURCES",
		items: [
			{
				name: 'Докумиус',
				description: 'Документы',
				icon: 'documius',
			},
			{
				name: 'Квалиар',
				description: 'Аттестации',
				icon: 'qualiar',
			},
			{
				name: 'Ориентус',
				description: 'Онбординг',
				icon: 'orientus',
			},
		],
	},
	{
		title: "LEADS & TOPS",
		items: [
			{
				name: 'Лориан',
				description: 'Карты знаний',
				icon: 'lorian',
			},
		],
	},
];


export const Sidebar: React.FC = () => {
	const [selectedSectionIndex, setSelectedSectionIndex] = useState<number>(0);
	const [selectedButtonIndex, setSelectedButtonIndex] = useState<number>(0);
	const [optionValue, setOptionValue] = useState<string>('GigaChat');


	const handleChange = (sectionIndex: number, buttonIndex: number) => {
		setSelectedSectionIndex(sectionIndex);
		setSelectedButtonIndex(buttonIndex);
	  };

	const handleOptionChange = (value: string) => {
		setOptionValue(value)
	}

	const options = [
		{
			label: 'GigaChat',
			onClick: () => handleOptionChange('GigaChat'),
			value: 'GigaChat'
			},
		{
		label: 'ChatGPT',
		onClick: () => handleOptionChange('ChatGPT'),
		value: 'ChatGPT'
		},
		{
		label: 'LaMDA',
		onClick: () => handleOptionChange('LaMDA'),
		value: 'LaMDA'
		},
		{
		label: 'LLaMA',
		onClick: () => handleOptionChange('LLaMA'),
		value: 'LLaMA'
		},
		{
		label: 'YandexGPT',
		onClick: () => handleOptionChange('YandexGPT'),
		value: 'YandexGPT'
		}
	]

  return (
    <section className={styles.wrapper}>
	<Icon icon='/icons/sidebar-logo.svg' />
	<div className={styles.container}>
    <ul className={styles.assists}>
	{SIDEBAR.map((section, sectionIndex) => (
		<li key={sectionIndex}>
		<Typography noWrap variant="overline-L">
			{ section.title }
		</Typography>
		<ul className={styles.list}>
			{section.items.map((item, buttonIndex) => (
			<li className={styles.list__item} key={buttonIndex}>
				<input
					checked={selectedButtonIndex === buttonIndex && selectedSectionIndex === sectionIndex}
					className={styles.radiobutton}
					onChange={() => handleChange(sectionIndex, buttonIndex)}
					type="radio"
					/>
					<Icon className={styles.icon} icon={'/icons/' + item.icon + '.svg'} />
					<section className={styles.info}>	
						<Typography noWrap className={`${styles.info__name} ${selectedButtonIndex === buttonIndex && selectedSectionIndex === sectionIndex ? styles.info__name_checked : ''}`} variant="content-M-500">
							{item.name}
						</Typography>
						<Typography noWrap className={`${styles.info__desc} ${selectedButtonIndex === buttonIndex && selectedSectionIndex === sectionIndex ? styles.info__desc_checked : ''}`} variant="content-M-400">
							{item.description}
						</Typography>
					</section>
				</li>
			))}
		</ul>
	  </li>
	))}
	</ul>
	<Select
		className={styles.test}
		onChange={function Qa(){}}
		options={options}
		placeholder="Языковая модель"
		size="M"
		value={optionValue}
	/>
	</div>
    </section>
  );
};
