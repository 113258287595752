import styles from "./index.module.sass";
import { Sidebar } from "../../components/Sidebar";
import { Chat } from "../../components/Chat";


export const Main = ({ className, ...rest }: any) => {

	return (
		<section className={styles.main}>
			<Sidebar />
			<Chat />
		</section>
	);
};
