import { BaseQueryFn, createApi } from "@reduxjs/toolkit/query/react";
import { Message } from "../models/message";
import axios, { AxiosError, AxiosRequestConfig } from "axios";

const backendApi = 'https://backend.demoai.technokratos.com'

const axiosInstance = axios.create({
  baseURL: backendApi
});


const axiosBaseQuery =
  ({ baseUrl }: { baseUrl: string } = { baseUrl: backendApi }): BaseQueryFn<
    {
      url: string;
      method?: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
      headers?: AxiosRequestConfig['headers'];
      signal?: AbortSignal;
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params, headers, signal }) => {
    try {
      const result = await axiosInstance({
        url: baseUrl + url,
        method,
        data,
        params: {
            ...params,
            validateStatus: false,
            withCredentials: false,
            disableSSLVerification: true
        },
        headers,
        signal
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const messageAPI = createApi({
  reducerPath: 'messageAPI',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Message'],
  endpoints: (build) => ({
    createMessage: build.mutation<Message, { value: string; signal: AbortSignal }>({
      query: ({ value, signal }) => ({
        url: `/api/chat`,
        method: 'POST',
        data: {
          message: value
        },
        signal
      }),
      invalidatesTags: ['Message']
    }),
  })
});

