import { createSlice } from "@reduxjs/toolkit";
import { Message } from "../../models/message";

interface MessageState {
    messages: Message[];
    isLoading: boolean;
    error: string
}

const initialState: MessageState = {
    messages: [],
    isLoading: false,
    error: ''
}

export const messageSlice = createSlice({
    name: 'message',
    initialState,
    reducers: {
        messageHistory(state) {}
    }
})

export default messageSlice.reducer;