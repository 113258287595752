import { Icon, Loader, Typography } from '@technokratos/prometheus-react';
import styles from "./index.module.sass";
import { Message, MessageDirection } from '../../app/api/models/message';

type MessageProps = {
	message?: Message;
	showLoader?: boolean
	ref?: React.RefObject<HTMLLIElement> | null
}


export const MessageItem = ({ message, showLoader = false, ref }: MessageProps) => {
  return (
	<section className={`${styles.message} ${message?.direction === MessageDirection.IN && styles.message__in}`}>
	{message?.direction === MessageDirection.OUT || showLoader ? (
	  <>
	  <Icon className={styles.message__icon} icon='/icons/bot.svg' size={48} />
		{showLoader ? (
			<span className={styles.message__text}>
			<Loader size={24} />
		</span>
		): (
			<span className={styles.message__text} dangerouslySetInnerHTML={{ __html: message?.message || '' }}></span>
		)}
	</>
	) : (
		<>
		<Typography variant='content-M-400'>
			<span className={styles.message__text}>
				{message?.message}
			</span>
	  </Typography>
		<img src='/images/avatar.png' alt="" />
	  </>
	)}
  </section>
  );
};

