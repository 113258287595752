import { combineReducers, configureStore } from "@reduxjs/toolkit";
import messageReducer from './reducers/MessageSlice'
import { messageAPI } from "../services/MessageService";

const rootReducer = combineReducers({
    messageReducer,
    [messageAPI.reducerPath]: messageAPI.reducer
});

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware()
                .concat(messageAPI.middleware)
    })
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
